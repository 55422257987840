import React from "react";
import "./App.css";

function App() {
  return (
    <div className="app-container">
      <div className="centered-box">It's building...</div>
    </div>
  );
}

export default App;
